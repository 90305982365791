<template>
  <v-container fluid>
    <v-row justify="center" align="center" class="h-40vh">
        <v-col cols="12" class="text-center">
          <img width="300px" src="@/assets/welcome.png">
        </v-col>
        <v-col cols="12" class="text-center font-weight-bold">
          Добро пожаловать, {{user.username}}!
        </v-col>
    </v-row>
    <v-row>
      <v-col align="center">
        <v-btn text color="primary">
          <v-icon>mdi-chevron-left</v-icon>
          пожалуйста, выберите пункт из меню
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'welcome',
  computed: {
    ...mapState('Auth', [
      'user',
    ]),
  }
}
</script>

<style>
.h-40vh {
  height: 50vh;
}
.blured-border {
  box-shadow: #fcfcfc 0px 0px 50px;
}
</style>
