<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <p class="text-h5">Помощь</p>
        Тут можно получить вопросы на часто задаваемые вопросы
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'help',
  }
</script>
